import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as webSocketActions from '../redux/actions/webSocketActions';

import SEO from '../components/SEO';
import TOC from '../components/TOC';

function CCPA({ siteConfig }) {
  return (
    <>
      <SEO
        title={'California Consumer Privacy Act Disclosure'}
        description={
          'At ' +
          siteConfig.brand +
          ', it is important to us that you understand how we use and share your personal information. This California Consumer Privacy Act disclosure outlines our use and disclosure of personal information.'
        }
      />
      <div className="page-container text-container">
        <h1>California Consumer Privacy Act Disclosure</h1>

        <div className="page">
          <div className="inner">
            <TOC />
            <div className="article-text">
              <p>Last Updated: January 03, 2022</p>
              <h2>Introduction</h2>
              <p>
                At {siteConfig.brand}, it is important to us that you understand
                how we use and share your personal information. This California
                Consumer Privacy Act disclosure outlines our use and disclosure
                of personal information. This notice identifies the categories
                of personal information we collect, describes how we use and
                share personal information, and explains how California
                residents can make certain requests regarding their personal
                information. {siteConfig.brand} does not sell your personal
                information to third parties for the third parties&rsquo; direct
                marketing purposes, so there is no need for you to
                &ldquo;opt-out&rdquo; of it.
              </p>
              <p>
                Note that while a category may be listed below that does not
                necessarily mean that we have collected personal information in
                that category about you.
              </p>
              <h2>Categories of Personal Information We Collect</h2>
              <table>
                <thead>
                  <tr>
                    <th>Category:</th>
                    <th>
                      To Whom We May Disclose This Type of Information for
                      Business Purposes:
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <strong>Identifiers</strong>&nbsp;&ndash; This may
                        include real name, alias, postal address, phone number,
                        unique personal identifier, online identifier, internet
                        protocol (IP) address, device ID, email address, account
                        name, signature, social security number, driver&rsquo;s
                        license number, passport number or other similar
                        identifiers.
                      </p>
                    </td>
                    <td>
                      <ul>
                        <li>Affiliate or business partners</li>
                        <li>Service providers</li>
                        <li>Product and service fulfillment companies</li>
                        <li>Payment processors and financial institutions</li>
                        <li>Data analytics providers</li>
                        <li>Internet service providers</li>
                        <li>Advertising networks</li>
                        <li>Social networks</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>Financial information</strong>&nbsp;&ndash; This
                        may include credit or debit card number, bank account
                        number, or other financial information.
                      </p>
                    </td>
                    <td>
                      <ul>
                        <li>Affiliate or business partners</li>
                        <li>Service providers</li>

                        <li>Product and service fulfillment companies</li>
                        <li>Payment processors and financial institutions</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>
                          Characteristics of protected classifications
                        </strong>
                        &nbsp;&ndash; This may include age, sex, race,
                        ethnicity, physical or mental handicap, etc.
                      </p>
                    </td>
                    <td>
                      <ul>
                        <li>Affiliate or business partners</li>
                        <li>Service providers</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>Commercial information</strong>&nbsp;&ndash;
                        This may include information about products or services
                        purchased, obtained, or considered, or other purchasing
                        or consuming histories or tendencies.
                      </p>
                    </td>
                    <td>
                      <ul>
                        <li>Affiliate or business partners</li>
                        <li>Service providers</li>
                        <li>Product and service fulfillment companies</li>
                        <li>Payment processors and financial institutions</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>Network activity data - </strong>Internet or
                        other electronic network activity information such as,
                        browsing history, search history, and information
                        regarding an individual&rsquo;s interaction with an
                        internet website, application, or advertisement
                      </p>
                    </td>
                    <td>
                      <ul>
                        <li>Affiliate or business partners</li>
                        <li>Service providers</li>
                        <li>Product and service fulfillment companies</li>
                        <li>Payment processors and financial institutions</li>
                        <li>Data analytics providers</li>

                        <li>Advertising networks</li>
                        <li>Social networks</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>Electronic and sensory data </strong>&ndash;
                        This may include audio, electronic, visual, thermal,
                        olfactory, or similar information (e.g., pictures, a
                        recording of a customer service call, security video
                        surveillance footage, video recording of website
                        sessions).
                      </p>
                    </td>
                    <td>
                      <ul>
                        <li>Affiliate or business partners</li>
                        <li>Service providers</li>
                        <li>Payment processors and financial institutions</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>
                          Professional or employment-related information.
                        </strong>
                      </p>
                    </td>
                    <td>
                      <ul>
                        <li>Affiliate or business partners</li>
                        <li>Service providers</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        <strong>Inferences </strong>&ndash; drawn from any of
                        the information listed above to create a profile
                      </p>
                    </td>
                    <td>
                      <ul>
                        <li>Affiliate or business partners</li>
                        <li>Service providers</li>
                        <li>Data analytics providers</li>
                        <li>Advertising networks</li>
                        <li>Social networks</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p>&nbsp;</p>
              <p>
                Any personal information that {siteConfig.brand} holds will be
                shared with applicable government entities, law enforcement,
                lawyers, auditors, consultants and other parties as required by
                law.
              </p>
              <h2>Privacy Rights Specific to California Residents</h2>
              <p>
                Under the California Consumer Privacy Act, California residents
                have specific rights regarding their personal information. This
                section describes Californians&rsquo; rights and explains how
                California residents can exercise those rights.
              </p>
              <p>
                California residents have the following specific rights under
                the California Consumer Privacy Act.
              </p>
              <ul>
                <li>
                  <strong>Right to Access</strong>. You have the right to
                  request that we disclose certain information to you about our
                  collection, use and disclosure of your Personal Information
                  over the past twelve (12) months. Any disclosures we provide
                  will only cover the 12-month period preceding the receipt of
                  your request. The response we provide will also explain the
                  reasons we cannot comply with a request, if applicable.
                </li>
                <li>
                  <strong>Right to Data Portability</strong>. You have the right
                  to a &ldquo;portable&rdquo; copy of your Personal Information
                  that you have submitted to us. Generally, this means you have
                  a right to request that we move, copy or transmit your
                  Personal Information stored on our servers or information
                  technology environment to another service provider&rsquo;s
                  servers or information technology environment.
                </li>
                <li>
                  <strong>Right to Delete Your Data</strong>. You have the right
                  to request that we delete any of your Personal Information
                  that we collected from you and retained, subject to certain
                  exceptions. Once we receive and confirm your verifiable
                  consumer request, we will delete your Personal Information
                  from our records, unless an exception applies.
                </li>
                <li>
                  <strong>
                    Right to Non-Discrimination for the Exercise of Your Privacy
                    Rights.
                  </strong>{' '}
                  You have the right not to receive discriminatory treatment by
                  us for exercising your privacy rights conferred by the
                  California Consumer Privacy Act.
                </li>
              </ul>
              <h2>Exercising Your Rights</h2>
              <p>
                To exercise your right to access, data portability, and deletion
                described above, please submit a verifiable consumer request to
                us by:
              </p>
              <ul>
                <li>
                  Our online Privacy Portal at:{' '}
                  <a href={'/ccpa-request/'} className="link">
                    www.{siteConfig.domain}.com/ccpa-request/
                  </a>
                </li>
                <li>
                  Email at:{' '}
                  <a
                    href={'mailto:privacy@' + siteConfig.domain}
                    className="link">
                    privacy@{siteConfig.domain}
                  </a>
                </li>
                <li>Call: (888) 969-4367</li>
              </ul>
              <p>
                Upon receiving your request, we will send you an email receipt
                confirming your request.
              </p>
              <p>
                Before responding to your request, we may need to obtain
                additional information to locate you in our records, verify that
                you are a California resident, and/or verify your identity
                depending on the nature of the request. If you are submitting a
                request on behalf of a household, we may need to verify each of
                those included as well.
              </p>
              <p>
                Authorized agents may exercise rights on your behalf, but at a
                minimum, we will require evidence of the agent&rsquo;s identity,
                proof of registration with the California Secretary of State,
                and at least one of the following evidencing proof of their
                legal authority to act: a written authorization signed by you;
                or a Certified copy of a Power of Attorney granted under Probate
                Code.
              </p>
              <p>
                We will respond to requests within 45 calendar days, unless we
                need more time (in which case we will notify you), and may take
                up to 90 calendar days in total to respond to your request(s).
              </p>
              <p>
                In some cases, our ability to uphold these rights for you may
                depend upon our obligations to process Personal Information for
                security, safety, fraud prevention reasons, compliance with
                regulatory or legal requirements, listed below, or because
                processing is necessary to deliver the services you have
                requested. Where this is the case, we will inform you of
                specific details in response to your request. We may deny your
                deletion request if retaining the information is necessary for
                us or our service providers to:
              </p>
              <ul>
                <li>
                  Complete the transaction for which we collected the Personal
                  Information, provide a good or service that you requested,
                  take actions reasonably anticipated within the context of our
                  ongoing business relationship with you, or otherwise perform
                  our contract with you;
                </li>
                <li>
                  Detect security incidents, protect against malicious,
                  deceptive, fraudulent, or illegal activity, or prosecute those
                  responsible for such activities;
                </li>
                <li>
                  Debug products to identify and repair errors that impair
                  existing intended functionality;
                </li>
                <li>
                  Exercise free speech, ensure the right of another consumer to
                  exercise their free speech rights, or exercise another right
                  provided for by law;
                </li>
                <li>
                  Comply with the California Electronic Communications Privacy
                  Act (Cal. Penal Code &sect; 1546 seq.);
                </li>
                <li>
                  Engage in public or peer-reviewed scientific, historical, or
                  statistical research in the public interest that adheres to
                  all other applicable ethics and privacy laws, when the
                  information&rsquo;s deletion may likely render impossible or
                  seriously impair the research&rsquo;s achievement, if you
                  previously provided informed consent;
                </li>
                <li>
                  Enable solely internal uses that are reasonably aligned with
                  consumer expectations based on your relationship with us;
                </li>
                <li>Comply with a legal obligation; or</li>
                <li>
                  Make other internal and lawful uses of that information that
                  are compatible with the context in which you provided it.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CCPA);
